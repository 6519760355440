.fade-in {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .fade-in.visible {
    opacity: 1;
  }
  
  .slide-up {
    transform: translateY(20px);
    opacity: 0;
    transition: transform 1s ease-out, opacity 1s ease-out;
  }
  
  .slide-up.visible {
    transform: translateY(0);
    opacity: 1;
  }
  
  .hero-fade-in {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  
  .hero-fade-in.visible {
    opacity: 1;
  }
  