@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;700&display=swap');

* {
  font-family: 'Nunito Sans', sans-serif;
}

@media only screen and (max-width: 768px) {

  /* Mobile styles */
  .mobile-only {
    display: block;
  }

  .computer-only {
    display: none;
  }
  .homepage-only {
    display: none;
  }
}

@media only screen and (min-width: 769px) {

  /* Computer styles */
  .mobile-only {
    display: none;
    background-color: #ffffff;
  }

  .computer-only {
    background-color: #ffffff;
    display: block;
    height: '100px';
    overflow: 'hidden' ;

  }

  .homepage-only {
    display: block;
    height: '100px';
    overflow: 'hidden' ;

  }
}

.computer-content {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
 





}

.computer-main-content {
  width: 50%;
  overflow-y: auto;
  height: 1000px;
  background-color: #ffffff;
}



.computer-main-content::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

.firstsection {
  width: 25%;
  border-left: '1.2px solid rgba(0, 0, 0, 0.1)' ;
  background-color: #ffffff;
  

}

.authfirstsection {
  width: 50%;
  border-left: '1.2px solid rgba(0, 0, 0, 0.1)' ;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  

}

.authlastsection {
  width: 60%;
 

}


.lastsection {
  width: 25%;
  background-color: #ffffff;

}

.selected-tab {
  position: relative;
}

.selected-tab::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 2px;
  background-color: #F21C29;
}



/* CSS */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.mobileoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.mobilepopup {
  background-color: #fff;
  width: 90%;
  border-radius: 20px;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.2);
}

.computerpopup {
  background-color: #fff;
  width: 50%;
  border-radius: 20px;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.2);
}

.popup {
  background-color: #fff;
  width: 25%;
  height: 90%;
  border-radius: 20px;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.2);
  z-index: 999

}

body.popup-open {
  overflow: hidden;
}

.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background to overlay everything */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure the popup is on top of everything */
}

.confirmation-dialog {
  position: relative;
  z-index: 1001; /* Ensure the popup is on top of the overlay */
  /* Add your existing styles for the confirmation dialog here */
}

/* @font-face {
  font-family: nuniblack;
  src: url(/Users/ernestawuku/Documents/business-bosses-web/src/assets/fonts/NunitoSans-Black.ttf);
}

.nuniblack{
  font-family: nuniblack;
} */

input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

::file-selector-button {
  display: none;
}

::-webkit-file-upload-button {
  display: none;
}





.image-container {
  position: relative;
  cursor: pointer;
}

.image-container.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* semi-transparent black background */
  z-index: 9999; /* make sure it's on top of other elements */
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container.fullscreen img {
  max-width: 100vw;
  max-height: 100vh;
  object-fit: contain;
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.marquee .marquee-text {
  animation: marquee 16s linear infinite; /* Double the animation time to account for both directions */
  display: inline-block;
  white-space: nowrap;
}

@keyframes marquee {
  0% { transform: translateX(60%); }
  50% { transform: translateX(-60%); } /* Reverse direction halfway */
  100% { transform: translateX(60%); } /* Continue reversing to the start */
}
